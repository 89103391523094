import type { RouteComponentProps } from "@reach/router";

import { SessionLoader } from "../session";
import { assertDefined } from "../utils";
import { ChangePasswordPageContents } from "./ChangePasswordPageContents";

export function ChangePasswordPage({ location }: RouteComponentProps) {
  assertDefined(location);

  return (
    <SessionLoader audience="authenticated" location={location}>
      <ChangePasswordPageContents locationPathname={location.pathname} />
    </SessionLoader>
  );
}
