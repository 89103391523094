import { css } from "@emotion/react";

/**
 * A tooltip which lists the password requirements.
 */
export function PasswordRequirementsTooltip() {
  return (
    <div
      className="absolute left-0 bottom-full"
      css={css`
        bottom: 100%;
        margin-bottom: 10px;
      `}
    >
      <span
        className="absolute z-10 left-30"
        css={css`
          top: 100%;
          width: 0;
          height: 0;
          border-top: 10px solid #f3f3f3;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
        `}
      />
      <div
        className="relative px-4 py-3 bg-white rounded-md shadow z-0"
        css={css`
          border-radius: 4px;
        `}
      >
        <h5 className="font-semibold text-lg">Choosing a Password</h5>
        <p>
          Your password must be classified as at least Strong. A good password
          consists of:
        </p>
        <ul className="list-inside list-disc">
          <li>
            <span>8 or more characters</span>
          </li>
          <li>
            <span>Mixture of letters and numbers</span>
          </li>
          <li>
            <span>Mixture of upper and lowercase</span>
          </li>
          <li>
            <span>Special characters</span>
          </li>
          <li>
            <span>Non-dictionary words</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
